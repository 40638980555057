<template>
  <div class="container">
    <Breadcrumb>
      <template slot="restPage">
        <template v-if="isEnrolledCounseling">
          <el-breadcrumb-item>
            <router-link
              :to="{
                name: 'counselingClassesDetail',
                params: { id: $route.params.counselingId }
              }"
            >
              <IsOnlineIcon :isOnline="sessionClass.course_session.is_online" />
              {{ sessionClass.title }}
            </router-link>
          </el-breadcrumb-item>
          <el-breadcrumb-item>
            <router-link
              :to="{
                name: 'counselingSchool',
                params: { id: $route.params.counselingId }
              }"
            >
              {{ pageTitle }}
            </router-link>
          </el-breadcrumb-item>
        </template>
        <template v-else>
          <el-breadcrumb-item>
            <router-link :to="{ name: 'counseling' }">
              Counseling
            </router-link>
          </el-breadcrumb-item>
          <el-breadcrumb-item>
            <router-link
              :to="{
                name: 'counselingUserSchool',
                params: { username: counselingStudent.username }
              }"
            >
              {{ pageTitle }}
            </router-link>
          </el-breadcrumb-item>
        </template>
        <el-breadcrumb-item>
          {{ `Edit ${schoolName}` }}
        </el-breadcrumb-item>
      </template>
    </Breadcrumb>
    <div class="title">
      <h1>
        <router-link
          :to="{
            name: 'School',
            params: { schoolId: collectedSchool.school.slug, type: 'overview' }
          }"
        >
          {{ schoolName }}
        </router-link>
      </h1>
    </div>
    <hr class="separate-line" />
    <el-form ref="collectedSchool" label-width="160px">
      <el-form-item prop="type" :label="$t('collectedSchool.form.category')">
        <el-select
          :value="collectedSchool.type"
          @change="(type) => setCollectedSchool({ type })"
        >
          <el-option :label="$t('schools.star.Dream school')" value="DREAM" />
          <el-option :label="$t('schools.star.Reach school')" value="IDEA" />
          <el-option :label="$t('schools.star.Fit school')" value="NORMAL" />
          <el-option :label="$t('schools.star.Safety school')" value="SAFETY" />
          <el-option :label="$t('schools.star.Uncategorized')" value="DEFAULT" />
        </el-select>
      </el-form-item>
      <el-form-item
        prop="is_applying"
        :label="$t('collectedSchool.form.applying')"
      >
        <el-radio-group
          :value="collectedSchool.is_applying"
          @input="(is_applying) => setCollectedSchool({ is_applying })"
        >
          <el-radio :label="1">{{ $t("collectedSchool.form.yes") }}</el-radio>
          <el-radio :label="0">{{ $t("collectedSchool.form.no") }}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        v-if="collectedSchool.is_applying"
        prop="application_type"
        :label="$t('collectedSchool.form.applicationType')"
      >
        <el-select
          :value="collectedSchool.application_type"
          @change="application_type => setCollectedSchool({ application_type })"
        >
          <el-option
            :key="applicationType"
            v-for="applicationType in schoolApplicationTypes"
            :label="applicationType"
            :value="applicationType"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        prop="application_portal"
        :label="$t('collectedSchool.form.applicationForm')"
      >
        <EssayThirdAppSelector
          :customStyle="{ width: 194 }"
          :value="collectedSchool.application_portal"
          :enableApps="collectedSchool.school.third_service_schools"
          @change="({ id }) => setCollectedSchool({ application_portal: id })"
        />
      </el-form-item>
      <el-form-item
        prop="application_submission_date"
        :label="$t('collectedSchool.form.applicationSubmissionDate')"
      >
        <el-date-picker
          :value="collectedSchool.application_submission_date"
          @input="(application_submission_date) => setCollectedSchool({ application_submission_date })"
          type="date"
          value-format="yyyy-MM-dd"
        />
      </el-form-item>
      <el-form-item
        prop="financial_submission_date"
        :label="$t('collectedSchool.form.financialAidSubmissionDate')"
      >
        <el-date-picker
          :value="collectedSchool.financial_submission_date"
          @input="(financial_submission_date) => setCollectedSchool({ financial_submission_date })"
          type="date"
          value-format="yyyy-MM-dd"
        />
      </el-form-item>
      <el-form-item
        prop="login_field_username"
        :label="$t('collectedSchool.form.applicationUsername')"
      >
        <el-input
          :value="collectedSchool.login_field_username"
          @input="(login_field_username) => setCollectedSchool({ login_field_username })"
        />
      </el-form-item>
      <el-form-item
        prop="login_field_password"
        :label="$t('collectedSchool.form.applicationPassword')"
      >
        <el-input
          show-password
          :value="collectedSchool.login_field_password"
          @input="(login_field_password) => setCollectedSchool({ login_field_password })"
        />
      </el-form-item>
      <el-form-item
        prop="admissions_result"
        :label="$t('collectedSchool.form.admissionsResult')"
      >
        <el-select
          :value="collectedSchool.admissions_result"
          @change="(admissions_result) => setCollectedSchool({ admissions_result })"
        >
          <el-option :label="$t('collectedSchool.form.admitted')" value="admitted" />
          <el-option :label="$t('collectedSchool.form.rejected')" value="rejected" />
          <el-option :label="$t('collectedSchool.form.deferred')" value="deferred" />
          <el-option :label="$t('collectedSchool.form.waitlisted')" value="waitlisted" />
        </el-select>
      </el-form-item>
      <el-form-item
        v-if="collectedSchool.admissions_result === 'admitted'"
        prop="is_enrolled"
        :label="$t('collectedSchool.form.enrolled')"
      >
        <el-radio-group
          :value="collectedSchool.is_enrolled"
          @input="(is_enrolled) => setCollectedSchool({ is_enrolled })"
        >
          <el-radio :label="1">{{ $t("collectedSchool.form.yes") }}</el-radio>
          <el-radio :label="0">{{ $t("collectedSchool.form.no") }}</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <el-row type="flex" justify="center">
      <el-button
        @click="backToCollectColleges"
      >
        {{ $t("button.cancel") }}
      </el-button>
      <el-button @click="updateCollectedSchool" type="primary">
        {{ $t("button.save") }}
      </el-button>
    </el-row>
  </div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";
import IsOnlineIcon from "@/components/icon/IsOnline";
import { EssayThirdAppSelector } from "@/components/selector";
import privateApi from "@/apis/private";
import profileApi from "@/apis/profile";
import College from "@/class/college";

export default {
  metaInfo() {
    return {
      title: "Colleges - Ivy-Way Academy"
    };
  },
  components: {
    Breadcrumb,
    IsOnlineIcon,
    EssayThirdAppSelector
  },
  computed: {
    pageTitle() {
      if (this.counselingStudent.first_name) {
        return `${this.counselingStudent.first_name || ""}'s Colleges`;
      } else {
        return "";
      }
    },
    schoolName() {
      const schoolNameI18nObject = this.collectedSchool.school.name;
      if (this.lang === "EN-US") {
        return schoolNameI18nObject[this.langList[this.lang]];
      } else {
        return `${schoolNameI18nObject[this.langList[this.lang]]} (${schoolNameI18nObject.en})`;
      }
    },
    lang() {
      return this.$store.getters["user/langValue"];
    },
    langList() {
      return {
        "ZH-TW": "tw",
        "EN-US": "en",
        "ZH-CN": "cn"
      };
    },
    collectedSchool() {
      return this.$store.getters["collectSchool/collectedSchool"];
    },
    isEnrolledCounseling() {
      return this.$route.name === "editCounselingCollectedColleges";
    },
    schoolApplicationTypes() {
      console.log(this.collectedSchool);
      if (this.collectedSchool.id) {
        return College.getSchoolApplicationTypes(this.collectedSchool);
      } else {
        return [];
      }
    }
  },
  data() {
    return {
      sessionClass: {
        course_session: {}
      },
      counselingStudent: {}
    };
  },
  async created() {
    if (this.isEnrolledCounseling) {
      await this.fetchCustomLesson(this.$route.params.counselingId);
    } else {
      await this.fetchUserProfile();
    }
    this.fetchCollectedSchool(this.$route.params.collectedCollegeId);
  },
  methods: {
    backToCollectColleges() {
      if (this.isEnrolledCounseling) {
        this.$router.push({
          name: "counselingSchool",
          params: { id: this.$route.params.counselingId }
        });
      } else {
        this.$router.push({
          name: "counselingUserSchool",
          params: {
            username: this.counselingStudent.username
          }
        });
      }
    },
    async fetchCustomLesson(counselingId) {
      const customLesson = await privateApi.getCustomLesson({
        class_id: counselingId
      });
      this.sessionClass = {
        ...customLesson.session_class
      };
      this.counselingStudentId =
        customLesson.student_class_tickets[0].student.id;
      this.counselingStudent = customLesson.student_class_tickets[0].student;
    },
    async fetchUserProfile() {
      const { basic_info, student: { counseling_schools_type } } = await profileApi.getUserByName(
        this.$route.params.username
      );
      this.counselingStudent = { ...basic_info, counseling_schools_type };
    },
    fetchCollectedSchool(collectedCollegeId) {
      this.$store.dispatch("collectSchool/fetchCollectedSchool", collectedCollegeId);
    },
    setCollectedSchool(collectedSchool) {
      this.$store.commit("collectSchool/setCollectedSchool", collectedSchool);
    },
    updateCollectedSchool() {
      this.$store.dispatch("collectSchool/updateCollectedSchool", () => {
        this.backToCollectColleges();
      });
    }
  }
};
</script>
